.unlock-modal {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 4px solid black;
  max-width: 600px;
  height: fit-content;
  padding: 24px;
  z-index: 2;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  &.hidden {
    display: none;
  }

  &__header {
    font-size: 14px;
    margin: 0 0 8px;
  }

  &__email,
  &__institution,
  &__specialty,
  &__application-year {
    &__input {
      width: 100%;
      border: 1px solid black;
      padding: 4px;
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  &__submit {
    margin-left: auto;
    background-color: darkgreen;
    color: white;
  }

  &__error {
    color: red;
  }
}