* {
  font-family: Arial, sans-serif;
  color: #3d3d3d;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

a {
  color: #41729f;
}

button {
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: 0;
  margin-right: 8px;
  font-size: 18px;
  cursor: pointer;

  svg {
    height: 18px;
    margin: auto 4px auto 0;
  }
}

.arcs {
  margin: 20px;
  max-width: 70em;
  margin: auto;
  padding: 64px 16px 0;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: gray;
    opacity: .8;
  }

  &__warning {
    padding: 8px;
    text-align: center;
    border: 20px solid black;
    border-image: repeating-linear-gradient(-55deg,
        yellow,
        yellow 20px,
        black 20px,
        black 40px) 20;

    span {
      color: white;
    }
  }

  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    color: white;
    opacity: .7;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    border-bottom: 4px solid black;
    padding: 8px 16px;

    &__container {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 70em;
      padding: 0 16px;
      margin: 0 auto;
    }

    &__title {
      margin: 0 0 4px;
      font-size: 24px;
    }

    &__subtitle {
      font-size: 12px;
      margin: 0;
    }

    &__buttons {
      display: flex;
      margin: auto 0 auto auto;
    }

    &__new-author,
    &__clear-author {
      display: inline-flex;
      height: fit-content;
      margin-left: 8px;
    }

    &__new-author {
      background-color: #c3e0e5;
    }
  }

  &__saved-authors {
    border: 1px solid black;
    padding: 16px;

    &__list {
      padding-left: 20px;
    }

    &__title {
      margin: 0 0 16px;
      font-size: 18px;
    }
  }

  &__filtered {
    border: 2px dotted red;
    padding: 16px;

    &__message {
      color: red;
      margin: 0 0 12px;
    }

    &__ids {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__left {
      width: 80%;
    }

    &__right {
      width: 20%;
      min-width: 100px;
      padding-left: 16px;
    }
  }

  &__results {
    display: flex;
    flex-direction: column;

    &__header {
      margin: 16px 0;
    }

    &__articles-list {
      list-style: none;
      padding: 0;
      margin: 0;
      // border-top: 1px solid #3d3d3d;
    }
  }
}

.hidden {
  display: none;
}

.lds-ring {
  color: white
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  color: white;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}