.scores {
  &__header {
    margin: 16px 0;
  }

  &__container {
    padding: 16px;
    border: 1px solid #3d3d3d;
    border-radius: 5px;
  }

  &__cumulative-pvu,
  &__cumulative-arcs,
  &__effort-index,
  &__h-index {
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: bold;

    &:empty {
      margin: 0;
    }
  }
}