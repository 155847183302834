.search-inputs {
  &__inputs {
    display: flex;
    flex-direction: column;

    &__last-name,
    &__first_initial,
    &__ids {
      display: flex;
      flex-direction: column;
      margin: 8px 0;
      width: 50%;

      @media (max-width: 767px) {
        width: 100%;
      }

      label {
        margin-bottom: 4px;
        font-size: 16px;
      }

      input,
      textarea {
        border: 1px solid black;
        padding: 4px;
        font-size: 14px;
      }
    }

    &__ids {
      textarea {
        min-height: 40px;
      }
    }
  }

  &__save-submit,
  &__export-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  &__save-submit {
    &__submit {
      background-color: #41729f;
      color: white;
    }
  }
}