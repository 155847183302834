.result {
  padding: 16px;

  h4 {
    padding-top: 0;
  }

  // &:nth-child(odd) {
  //   background-color: #eeeeee;
  // }

  &__title {
    margin: 0 0 16px;
  }

  &__score-picker {
    font-size: 12px;
  }

  &__high-impact-pub {
    &__icon {
      color: darkred;

      &.high-impact {
        color: darkgreen;
      }
    }
  }

  &__pvu {
    background-color: #c3e0e5;
    margin: 16px -8px;
    padding: 8px;
    font-size: 16px;
  }
}

.flagged-pes {
  border: red;
  border-radius: 5px;
  border-width: 5px;
  border-style: solid;
}